import '../layout.css';

import { StaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

import { Button } from '@material-ui/core';

import Footer from '../Footer';
import Header from '../Header';

const MainStyled = styled.main`
  margin-top: ${props => (props.page !== 'subpage' ? '0' : '8rem')};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: underline;
  }
`;

const MaterialButton = props => {
  const { style, onClick, children } = props;
  return (
    <Button
      color={style.color}
      variant={style.variant}
      size={style.size}
      type={style.type}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const Layout = ({ page, children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        <CookieConsent
          location="bottom"
          buttonText="Akzeptieren"
          cookieName="cookieAcceptCookie"
          style={{ background: '#2B373B' }}
          buttonStyle={{
            variant: 'contained',
            type: 'submit',
            color: 'primary',
          }}
          ButtonComponent={MaterialButton}
        >
          Diese Website benutzt Cookies um die Nutzererfahrung zu verbessern.{' '}
          Erfahre mehr über die von uns verwendeten Cookies in unserer{' '}
          <StyledLink to="datenschutz">Datenschutzerklärung</StyledLink>.
        </CookieConsent>
        <Header />
        <MainStyled page={page}>{children}</MainStyled>
        <Footer />
      </>
    )}
  />
);

MaterialButton.propTypes = {
  style: PropTypes.objectOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

MainStyled.propTypes = {
  page: PropTypes.string,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string,
};

Layout.defaultProps = {
  page: '',
};

export default Layout;
