import styled from 'styled-components';
import colors from '../../settings/colors';

export const FooterStyled = styled.footer`
  padding: 2rem 0;
  background: ${colors.grey10};
  color: ${colors.grey120};

  nav {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  a {
    color: ${colors.grey120};
    text-decoration: none;

    &:hover {
      color: ${colors.red100};
      text-decoration: underline;
    }
  }
`;

export const Copyright = styled.div`
  text-align: center;
  padding: 2rem 0;
  margin-top: 2rem;
  border-top: 1px solid ${colors.grey20};
`;
