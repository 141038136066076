import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Container from '../Container';
import Logo from '../../images/oetzi-logo.inline.svg';

const StyledHeader = styled.header`
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
`;

const LogoLink = styled(Link)`
  display: inline-flex;
  padding: 1rem 1.8rem 1rem 2rem;
  box-sizing: border-box;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top: none;
  background: #c3282d;
  transition: background 0.2s;

  &:hover {
    background: #25406d;
  }
`;

const StyledLogo = styled(Logo)`
  height: 2rem;

  g {
    fill: white;
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <Container>
        <LogoLink to="/">
          <StyledLogo />
        </LogoLink>
      </Container>
    </StyledHeader>
  );
};

export default Header;
