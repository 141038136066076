import { Link } from 'gatsby';
import React from 'react';
import Grid from '@material-ui/core/Grid';

import Container from '../Container';
import { FooterStyled, Copyright } from './styles';

const Footer = () => (
  <FooterStyled>
    <Container>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={4} md={6}>
          <h3>Über uns</h3>
          <p>
            Wir bei Robin Heat trinken unseren Kaffee sehr langsam. Um auch den
            letzten Schluck aus der Tasse heiß genießen zu können, haben wir die
            OETZI Tasse entwickelt. Eine ästhetische Tasse, die den Kaffe länger
            als 30 Minuten heiß hält.
          </p>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <h3>Soziale Medien</h3>
          <nav>
            <ul>
              <li>
                <a href="https://www.instagram.com/getoetzicom/">Instagram</a>
              </li>
              <li>
                <a href="https://www.facebook.com/Oetzi-107917450568833/">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://www.twitte.com/getoetzi/">Twitter</a>
              </li>
            </ul>
          </nav>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <h3>Rechtliches</h3>
          <nav>
            <ul>
              <li>
                <Link to="/impressum">Impressum</Link>
              </li>
              <li>
                <Link to="/datenschutz">Datenschutz</Link>
              </li>
            </ul>
          </nav>
        </Grid>
      </Grid>
      <Copyright>
        © {new Date().getFullYear()},{' '}
        <a href="https://robinheat.de">Robin Heat GmbH</a>. Alle Rechte
        vorbehalten.{' '}
      </Copyright>
      <br />
    </Container>
  </FooterStyled>
);

export default Footer;
